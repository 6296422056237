<template>
  <div id="header-bar-container" class="d-flex flex-row flex-nowrap justify-space-between align-start">
    <div v-if="description !== '-'" class="flex-grow-1">
      <div id="header-bar-title">{{ title }}</div>
      <div id="header-bar-description">{{ description }}</div>
    </div>
    <div v-else id="header-bar-title-only" class="flex-grow-1">{{ title }}</div>
    <div v-if="$vuetify.breakpoint.width > 600" id="header-bar-logo">
      <LogoText style="height: 80px" :fill="'#ffffff'"/>
    </div>
  </div>
</template>

<script>
import LogoText from '../assets/LogoText';

export default {
  name: 'HeaderBar',
  components: {LogoText},
  props: {
    title: {type: String, required: true},
    description: {type: String, required: false, default: '-'}
  }
};
</script>

<style scoped>
#header-bar-container {
  background-color: #336799;
  color: #ffffff;
  height: 100px;
  margin-top: 55px;
  width: 100%;
}

#header-bar-title {
  height: 50px;
  font-size: 29px;
  font-weight: 700;
  padding: 15px 50px 0 15px;
}

#header-bar-description {
  height: 50px;
  font-size: 20px;
  padding: 0 50px 15px 15px;
}

#header-bar-title-only {
  font-size: 32px;
  font-weight: 700;
  margin: auto 0;
  padding: 15px;
}

#header-bar-logo {
  height: 80px;
  margin: 10px 20px;
  width: 127px;
}

@media (max-width: 600px) {
  #header-bar-container {
    height: auto;
    margin-top: 30px;
  }

  #header-bar-title {
    font-size: 26px;
    height: auto;
  }

  #header-bar-description {
    font-size: 18px;
    height: auto;
  }

  #header-bar-title-only {
    font-size: 26px;
    margin: 0;
  }
}
</style>
