<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.warm-up.title')" :description="$t('headers.warm-up.description')"/>

    <!-- Square buttons -->
    <SquareButtonGroup :buttons="buttons"/>

    <!-- Footer -->
    <Footer :quote="true"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';
import SquareButtonGroup from '../components/SquareButtonGroup';
import Footer from '../components/Footer';

export default {
  name: 'MainWarmUp',
  components: {Header, SquareButtonGroup, Footer},
  data: () => ({
    buttons: [
      {icon: '$i-addition', title: 'events.addition', link: '/warm-up/addition'},
      {icon: '$i-colors', title: 'events.colors', link: '/warm-up/colors'},
      /*{icon: '$i-shapes', title: 'events.shapes', link: '/warm-up/shapes'},*/
      {icon: '$i-sequence', title: 'events.sequence', link: '/warm-up/sequence'}
    ]
  })
};
</script>
